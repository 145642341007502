import MetaTag from "components/Common/Meta-tag"
import React from "react"
import { Container } from "reactstrap"

import Breadcrumbs from "../../components/Common/Breadcrumb"
import DatatableTables from "./DatatableTables"

function Shop() {
  return (
    <>
      <MetaTag title="All Shops" />

      <div className="page-content">
        <Breadcrumbs title="Dashboard" breadcrumbItem="All Shops" />
        <Container fluid>
          <div className="container-fluid">
            <DatatableTables />
          </div>
        </Container>
      </div>
    </>
  )
}

export default Shop
