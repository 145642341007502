import MetaTag from "components/Common/Meta-tag"
import React from "react"

import StoreDetail from "./Details"

function StoreDetails() {
  return (
    <>
      <MetaTag title={"Store"} />

      <StoreDetail />
    </>
  )
}

export default StoreDetails
